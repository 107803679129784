import { PageProps } from "gatsby";
import React from "react";

import { Search } from "../components/pages/Search";

import { snProps } from "../js/utils";

const SearchPage = (props: PageProps) => (
  <Search {...{ ...snProps, ...props }} />
);

export default SearchPage;
